import React,{ useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { isAuthenticated } from "./Auth";

const ProtectedRoute = ({children}) => {
    const navigate = useNavigate();

    // You should call navigate() in a React.useEffect(), not when your component is first rendered.
    useEffect(() => {
        if (!isAuthenticated()) {
          navigate('/l');
        }
      }, [navigate]);
    
      if (!isAuthenticated()) {
        return null; // 確保未登入的使用者不會看到受保護的內容
      }

    return children;
}

export default ProtectedRoute;