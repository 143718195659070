// src/components/Layout.js
import React from 'react';
import Header from '../components/Header/Header';
import Footer from './Footer';
import AuthProvider from "../store/AuthProvider";

const Layout = ({ children }) => {
  return (
    <div className="d-flex flex-column min-vh-100">
      <AuthProvider>
        <Header />
      </AuthProvider>
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;