import React, { useEffect, useState } from 'react';
import { getTest } from '../../api/api';
import { useNavigate } from 'react-router-dom';
import '../../assets/post.css';
import '../../assets/common.css';

const PostIdea = () => {
    let placeholder = "大家好我們是LaunchCool，簡單介紹我們的團隊。\n我們團隊主要是由業界領域內的高手組成，都有5~10年的經歷，平常都是遠端工作，每兩週會有一次的線上聚會～。 \n\n前端開發（2人負責）：使用ReactJS \n後端開發（1人負責）：規劃使用NodeJS \n營運（3人負責）：規劃使用Google AdWords、行銷... \n\n歡迎還有興趣的人加入！";
    let placeholderDescribeIdea = "\n 一句話介紹你的點子 : \n 創業第一站，有好點子還要好夥伴，來LaunchCool找吧";

    // store variable value and define a function called setData.
    const [data, setData] = useState([]);
    const [isClassActive, setIsExpanded] = useState(true);
    const navigate = useNavigate();
    

    // enter this page and mount will trigger this function.
    // dependency is empty array, so it will only trigger once.
    useEffect(() => {
        getTest()
            .then(data => setData(data))
            .catch(error => console.log(error));
    }, []);

    const handleProfileClick = () => {
        // 可以在這裡執行其他操作，例如記錄點擊事件
        navigate(`/a?id=1`);
    };

    return (
        <div>
            <div className="mb-5 content-main">
                <div className=''>
                    <a className='remove-link-css' href='/a?id=1'>
                        <div>
                            {false ? 
                            <img src="https://via.placeholder.com/40" className="post-profile-image"/> 
                            : 
                            <span className='post-profile-image-FirstName'>J</span>}
                            <span className='post-profile-name'>Jim Liu</span>
                        </div>
                    </a>
                </div>
            
                <div className='post-ideaheader'>Sharers</div>

                <h6 className="post-item-introduce">項目介紹</h6>
                <p className='post-item-content'>{placeholderDescribeIdea}
                </p>

                <h6 className="post-item-introduce">團隊成員</h6>
                <p className='post-item-content'>前端工程師 X 1：</p>
                <p className='post-item-content'>1.負責所有事情，企劃、程式開發、美術</p>

                <p className='post-item-content'>後端工程師 X 3：</p>
                <p className='post-item-content'>1.負責所有事情，企劃、程式開發、美術</p>
                <p className='post-item-content'>2.負責所有事情，企劃、程式開發、美術</p>

                <h6 className="post-item-introduce">招募說明</h6>
                <p className='post-item-content'>面試前請先準備面試考古題，練熟了比起來不會緊張，可能要提早到因為公司位置不太好找，轉接了好幾個人才能找到面試地點。</p>

                <div className="d-flex justify-content-between">
                    {/* <div className="hearts">
                        <span className="heart red fa-regular fa-heart"></span>
                        <span className='heartChar'>8/10</span>

                        <span className="heart black fa-regular fa-eye"></span>
                        <span className='heartChar'>815</span>
                    </div> */}
                    <div className="hearts">
                        <span className="heart red">♥</span>
                        <span className="heart red">♥</span>
                        <span className="heart red">♥</span>
                        <span className="heart black">♥</span>
                        <span className="heart black">♥</span>
                        <span className='heartChar'>(1234)</span>
                        {/* <span className="heartChar">新北 · 2021.06.30</span>
                        <span className="heartChar">＃爭夥伴！</span> */}
                    </div>
                    <div>
                        <span className="shareIcon fa-regular fa-heart"></span>
                        <span className="fa-solid fa-share shareIcon"></span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PostIdea;