import React, { useEffect, useState,useRef } from 'react';
import { getTest } from '../../api/api';
import { useNavigate } from 'react-router-dom';
import Heart from '../Heart';
import '../../assets/post.css';
import '../../assets/common.css';

const PostIdeaBrief = () => {
    // store variable value and define a function called setData.
    const [rating, setRating] = useState(0); // 保存當前評分
    const [data, setData] = useState([]);
    const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
    const [isModalOpen, setIsModalOpen] = useState(false); // 控制 modal 的顯示狀態
    const [formData, setFormData] = useState({
        'IdeaPost':'Sharers'
    });
    const modalTriggerRef = useRef(null); // 用於獲取按鈕位置
    // const toggleModal = () => {
    //     setIsModalOpen((prev) => !prev);
    // };


    // enter this page and mount will trigger this function.
    // dependency is empty array, so it will only trigger once.
    useEffect(() => {
        getTest()
            .then(data => setData(data))
            .catch(error => console.log(error));
    }, []);

    const toggleModal = () => {
        if (!isModalOpen && modalTriggerRef.current) {
          const rect = modalTriggerRef.current.getBoundingClientRect();
          setModalPosition({
            top: rect.top + window.scrollY - 115, // 按鈕底部位置
            left: rect.left + window.scrollX - 185, // 按鈕左側位置
          });
        }
        setIsModalOpen((prev) => !prev);
      };

    let idFromAPI = 1;
    return (
        <div>
            <div className="mb-5 content-main">
                <div className=''>
                    <a className='remove-link-css' href='/a?id=1'>
                        <div>
                            {false ? 
                            <img src="https://via.placeholder.com/40" className="post-profile-image"/> 
                            : 
                            <span className='post-profile-image-FirstName'>J</span>}
                            <span className='post-profile-name'>Jim Liu</span>
                        </div>
                    </a>
                </div>
                <a className='remove-link-css' href={`/p?id=${idFromAPI}`}>
                    <div className='post-ideaheader'>{formData.IdeaPost}</div>
                                
                    <h6 className="post-item-introduce">項目介紹</h6>
                    <p className='post-item-content'>一進去會先筆試 30分，接著由一個主管帶著資深工程師一起來面，簡單自我介紹
                        ，開始針對剛剛筆試結果討論，接著問之前寫的專案遇到什麼問題怎麼解決，
                        以及之前工作經歷，再來一些常見面試題目，最後談到薪資福利，整體來說算是愉快。 more...
                    </p>
                </a>
                <div className="d-flex justify-content-between">
                         <div className="hearts">
                            <span className="heart red fa-solid fa-heart"></span>
                            <span className="heart red fa-solid fa-heart"></span>
                            <span className="heart red fa-solid fa-heart"></span>
                            <span className="heart black fa-solid fa-heart"></span>
                            <span className="heart black fa-solid fa-heart"></span>
                            <span className='heartChar'>(1234)</span>
                       
                        {/* <span className="heartChar">新北 · 2021.06.30</span>
                        <span className="heartChar">＃爭夥伴！</span> */}
                    </div>
                    <div>
                        <span onClick={toggleModal} ref={modalTriggerRef} className={`shareIcon fa-solid fa-heart ${rating > 0 ? "heart red fa-solid":"black"}`}></span>
                        <span className="fa-solid fa-share shareIcon"></span>
                        { isModalOpen && <Heart rating={rating} setRating={setRating} modalPosition={modalPosition} formData={formData} toggleModal={toggleModal} />}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PostIdeaBrief;