import React, { useEffect, useState, forwardRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../assets/createpost.css';
import AccountEditPage1 from '../components/Account/AccountEditPage1';
import AccountEditPage2 from '../components/Account/AccountEditPage2';

const AccountEdit = () => {
    const [page, setPage] = useState(1);
    const [formData, setFormData] = useState({
        'Name': '',
        'ShortIntroduce': '',
        'IntroduceSelf': '',
        'Skill': '',
        'JobExperiences': '',
        'EducationalBackground': '',
        'SocialMedia_Line': '',
        'SocialMedia_GitHub': '',
        'SocialMedia_IG': '',
        'SocialMedia_EMAIL': '',
    });

    const updateFormData = (data) => {
        setFormData((prevFormData) => ({ ...prevFormData, ...data }));
    };

    // 取得查詢字串
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const p = queryParams.get('p');

    const page1 = () => setPage(1);
    const page2 = () => setPage(2);

    return (
        <div className="container content-wrapper">
            <div className="row">
                <div className="col-lg-8 mx-auto">
                    {
                        page === 2 ? (<AccountEditPage2 formData={formData} updateFormData={updateFormData} nextPage={page1} />) :
                            (<AccountEditPage1 formData={formData} updateFormData={updateFormData} nextPage={page2} />)
                    }
                </div>
            </div>
        </div>
    );
}

export default AccountEdit;