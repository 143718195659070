import React, { useState } from "react";
import "../assets/heart.css";

const Heart = ({ formData, toggleModal,modalPosition,rating,setRating }) => {

  const handleRatingSubmit = () => { };

  // 更新評分
  const handleRating = (star) => {
    setRating(star); // 更新到點擊的愛心數
    setTimeout(() => {
      toggleModal();
    }, 400);

    handleRatingSubmit();
  };

  return (
    <>
    <div
            onClick={toggleModal} // 點擊遮罩層關閉
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)", // 半透明遮罩
              zIndex: 999, // 確保遮罩層在最上面
            }}
          ></div>

<div
    style={{
      position: "absolute",
      top: modalPosition.top,
      left: modalPosition.left,
      backgroundColor: "#fff",
      padding: "10px 20px",
      borderRadius: "10px",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      zIndex: 1000,
    }}
    >
      <div onClick={(e) => e.stopPropagation()}>
        <div className="post-idea-small-desc">點一下評分</div>
        <div className="hearts-modal">
          {[1, 2, 3, 4, 5].map((star) => (
            <span
              key={star}
              className={`heart-modal fa-solid fa-heart ${star <= rating ? "red" : "black"}`} // 根據評分動態顯示顏色
              onClick={() =>{handleRating(star);}}
            >
            </span>
          ))}
        </div>
        {/* <div className="text-end">
          <div className="btn" onClick={() => { toggleModal(); handleRatingSubmit(); }}>
            確定
          </div>
        </div> */}
      </div>
    </div>
    </>
   
  );
};

export default Heart;
