// FirstPage.js
import React,{ useState } from 'react';

const CreateIdeaPostPage1 = ({ formData, updateFormData, nextPage }) => {
    let placeholder = "Example : \n大家好我們是LaunchCool，簡單介紹我們的團隊。\n我們團隊主要是由業界領域內的高手組成，都有5~10年的經歷，平常都是遠端工作，每兩週會有一次的線上聚會～。 \n\n前端開發（2人負責）：使用ReactJS \n後端開發（1人負責）：規劃使用NodeJS \n營運（3人負責）：規劃使用Google AdWords、行銷... \n\n歡迎還有興趣的人加入！";
    let placeholderDescribeIdea = "Example :\n 一句話介紹你的點子 : \n 創業第一站，有好點子還要好夥伴，來LaunchCool找吧";

    const [isFocused, setIsFocused] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        updateFormData({ [name]: value });
    };

    return (
        <div>
            <div className='post-create-item-title'>分享創意項目，讓大家評分這是不是一個好點子！</div>
            <p className='mb-5 post-create-item-title-describe'>不怕點子被偷走，只怕點子不值錢。</p>

            <div className='content-main mb-5'>
                <div className="mb-5">
                    <div className='post-create-item-subtitle'>項目名稱</div>
                    <label className="post-create-item-title-describe">好的名稱讓人一眼就記住。（內容最多20字）</label>
                    <input 
                        name='IdeaTitle' 
                        type="text"  
                        className={`input-custom ${isFocused ? 'focused' : ''}`} 
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => setIsFocused(false)} 
                        placeholder='Example : LaunchCool' 
                        value={formData.IdeaTitle || ''} onChange={handleChange} />
                </div>

                <div className="mb-5">
                    <div className='post-create-item-subtitle'>項目介紹</div>
                    <label className="post-create-item-title-describe">一句話介紹 / 3個主要功能介紹 / 詳細說明你的點子（內容需填寫 100 字以上）</label>
                    <textarea name='SentenceDescribe' className="textarea-custom" rows="9"
                        placeholder={placeholderDescribeIdea} value={formData.SentenceDescribe || ''} onChange={handleChange}></textarea>
                </div>

                {/* &#13;&#10; */}
                <div className="mb-5">
                    <div className='post-create-item-subtitle'>團隊介紹</div>
                    <p className="post-create-item-title-describe">這是一個讓人認識你們的機會，讓人知道為什麼你們能完成這麼棒的點子。（內容需填寫 100 字以上）</p>
                    <textarea name='IntroduceGroup' className="textarea-custom" rows="9"
                        placeholder={placeholder} value={formData.IntroduceGroup || ''} onChange={handleChange}></textarea>
                </div>

                <div className="text-end mb-3">
                    <div className='btn' onClick={nextPage}>下一步 &gt;</div>
                </div>
            </div>
        </div>
    );
}

export default CreateIdeaPostPage1;
