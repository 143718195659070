import React, { useRef } from "react";
import EditorJS from '@editorjs/editorjs';
import Header from "@editorjs/header";
import List from "@editorjs/list";
import Paragraph from "@editorjs/paragraph";
import "../../assets/editor.css";
import "editorjs-style";
/* index.css 或 App.css */


const EditorTest = () => {
    const [data, setData] = React.useState({});

    const TOOLS = {
        list: List,
        image: Image,
        header: Header,
        paragraph: Paragraph,
    }

    const editor = new EditorJS({
        holder: 'editorjs',
        tools: TOOLS,
        onReady: () => {
            console.log('Editor.js is ready to work!')
        },
        onChange: async (api, event) => {
            const savedData = await editor.save();
            // setData(savedData);
            console.log("Auto-Saved Data:", savedData);
            console.log('Now I know that Editor\'s content changed!', event)
        },
        data: {
          }
    })

    const handleSave = async () => {
        try {
          console.log("Saved Data:", data);
          // 你可以將 savedData 存儲到後端或其他地方
        } catch (error) {
          console.error("Save failed:", error);
        }
    };
      

    return (
        <div>
            <h1>React Editor.js Example</h1>
            <div id="editorjs" className="editor-container"></div>
            <button onClick={handleSave}>Save Content</button>
        </div>
    );
};

export default EditorTest;