import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home';
import Post from './pages/Post';
import CreatePost from './pages/CreatePost';
import Account from './pages/Account';
import AccountSelf from './pages/AccountSelf';
import AccountEdit from './pages/AccountEdit';
import AccountIdeaPost from './pages/AccountIdeaPost';
import Login from './pages/Login';
import Register from './pages/Register';
import ProtectedRoute from './utils/ProtectedRoute';
import AuthProvider from "./store/AuthProvider";
import EditorTest from './components/Post/EditorTest';
import MarkdownTest from './components/Post/MarkdownTest';
import MarkdownTestInput from './components/Post/MarkdownTestInput';

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
        <Route path="/m" element={<MarkdownTest />} />
        <Route path="/mi" element={<MarkdownTestInput />} />
          <Route path="/e" element={<EditorTest />} />
          <Route path="/" element={<Home />} />
          <Route path="/p" element={<Post />} />
          <Route path="/cp" element={
            <ProtectedRoute>
              <CreatePost />
             </ProtectedRoute>
          } />
          <Route path="/a" element={<Account />} />
          <Route path="/l" element={
            <AuthProvider>
              <Login />
            </AuthProvider>
            } />
          <Route path="/r" element={<Register />} />
          <Route path="/as" element={
            <ProtectedRoute>
              <AccountSelf />
           </ProtectedRoute>
            } />
          <Route path="/aip" element={
            <ProtectedRoute>
              <AccountIdeaPost />
            </ProtectedRoute>
            } />
            <Route path="/ae" element={
            <ProtectedRoute>
              <AccountEdit />
            </ProtectedRoute>
            } /> 
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
