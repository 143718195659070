import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../assets/account.css';
import '../../assets/common.css';

const AccountBriefSideListSub = () => {
    let idFromAPI = 1;
    return (
        <div className='account-list-div'>
            <a className='account-link-list' href={`/a?id=${idFromAPI}`}>
                <div className='row'>
                    {false ?
                        <div className='col-lg-1 col-1'>
                            <img src="https://via.placeholder.com/40" className="account-profile-list-image" />
                        </div>
                        :
                        <div className='col-lg-2 account-profile-image-list-FirstName'>J</div>}
                    <div className='col-lg-9 col-9'>
                        <span className='account-profile-list-name'>Jim Liu</span>
                        <span className='account-profile-name-list-subtitle'>Fu Jen Catholic University, 學士學位, 電機工程學系, 2015 ~ 2019</span>
                    </div>
                </div>
                {/* <div>
                    <span className='postContentColor'>大家好我是Jim，是ASP.NET Core工程師，開發過區塊鏈錢包 會寫Side more...
                    </span>
                </div> */}
            </a>
        </div>
    );
};

export default AccountBriefSideListSub;