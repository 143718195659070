import React, { useEffect, useState, forwardRef } from 'react';

const AccountIdeaPost = () => {
    return(
        <div className="container content-wrapper">
            <div className="row">
                <div className="col-lg-8 mx-auto">
                    <div className='content-main mb-5'>
                        <h1>Account Idea Post</h1>
                        <p>Account Idea Post page content</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AccountIdeaPost;

