import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../assets/account.css';
import '../../assets/common.css';

const AccountBrief = () => {
    let idFromAPI = 1;
    return (
        <div className="mb-5 content-main">
            <div className='row'>

                <div className='col-lg-3'>
                    {false ?
                        <img src="https://via.placeholder.com/40" className="account-profile-image" />
                        :
                        <div className='account-profile-image-FirstName'>J</div>}
                </div>
                <div className='col-lg-9'>
                <a className='remove-link-css' href={`/a?id=${idFromAPI}`}>
                    <div className='mb-3'>
                        <span className='account-profile-name'>Jim Liu</span>
                        <span className='account-profile-name-subtitle'>輔仁大學 Fu Jen Catholic University, 學士學位, 電機工程學系, 2015 ~ 2019</span>
                    </div>
                    <p className='postContentColor'>大家好我是Jim，是ASP.NET Core工程師，開發過區塊鏈錢包 API Server、VPN API Server，投保系統、銀行地球系統等等，程式撰寫風格為SOLID、KISS、DRY，主要開發多層架構，可以獨立開發設計一整個系統，熱愛寫程式平常會寫Side more...
                    </p>
                </a>
                <div className='profileAdditioinInfoIcon'>
                    <span className="fa-brands fa-github"></span>
                    <span className="fa-brands fa-line marginLeft10px"></span>
                    <span className="fa-brands fa-instagram marginLeft10px"></span>
                    <span className="fa-solid fa-envelope marginLeft10px"></span>
                </div>
                </div>
            </div>
        </div>
    );
};

export default AccountBrief;