import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeHighlight from "rehype-highlight";
// import "highlight.js/styles/github.css";
import "highlight.js/styles/atom-one-dark.css";
import "../../assets/editor.css";


const MarkdownTestInput = () => {
    const [markdown, setMarkdown] = useState('');

    const handleChange = (e) => {
        setMarkdown(e.target.value);
    }

    const customRenderers = {
        img: ({ src, alt }) => (
            <img
                src={src}
                alt={alt}
                style={{
                    maxWidth: "100%",
                    height: "auto",
                    cursor: "pointer",
                }}
                onClick={() => window.open(src, "_blank")}
            />
        ),
    };

    return (
        <div className="container content-wrapper" style={{ marginBottom: "100px" }}>
            <div className="row">
                <div className="col-lg-10 mx-auto">
                    <div className="content-main">
                        <textarea onChange={handleChange} type="text" rows={5} style={{ width: "600px" }}></textarea>
                        <ReactMarkdown
                            components={customRenderers}
                            
                            remarkPlugins={[remarkGfm]}
                            rehypePlugins={[rehypeHighlight]}
                        >
                            {markdown}</ReactMarkdown>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MarkdownTestInput;