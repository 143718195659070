import React, { useRef } from "react";
import "../../assets/editor.css";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeHighlight from "rehype-highlight";
import "highlight.js/styles/github.css";


const MarkdownTest = () => {
    const markdown2 = `
# Markdown Image Example

Here is a simple image:

![React Logo](https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg)

You can also add a link to the image:

[![React Logo](https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg)](https://reactjs.org/)
`;
    const markdown = `
# Hello, React Markdown!

This is a simple example of rendering **Markdown** in a React application.

## Features

- **Bold** and _Italic_
- [Links](https://reactjs.org)
- \`Inline code\`
- Block code with syntax highlighting:
  
\`\`\`javascript
const greet = () => {
  console.log("Hello, World!");
};
greet();
\`\`\`

| Feature       | Supported |
|---------------|-----------|
| Tables        | ✅         |
| Task Lists    | ✅         |
| Syntax Highlight | ✅       |
  `;

    const customRenderers = {
        img: ({ src, alt }) => (
            <img
                src={src}
                alt={alt}
                style={{
                    maxWidth: "100%",
                    height: "auto",
                    cursor: "pointer",
                }}
                onClick={() => window.open(src, "_blank")}
            />
        ),
    };

    return (
        <div className="container content-wrapper" style={{ marginBottom: "100px" }}>
            <div className="row">
                <div className="col-lg-10 mx-auto">
                    <div className="content-main">
                        <ReactMarkdown
                            components={customRenderers}
                            children={markdown}
                            remarkPlugins={[remarkGfm]}
                            rehypePlugins={[rehypeHighlight]}
                        />
                        <ReactMarkdown components={customRenderers}>{markdown2}</ReactMarkdown>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MarkdownTest;