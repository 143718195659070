import React,{useContext} from 'react';
import '../../assets/Modal.css';
import '../../assets/header.css';
import { AuthContext } from "../../store/AuthProvider";

const HeaderSettingModal = ({ closeModal }) => {
    const { isAuthenticatedProvider, logout } = useContext(AuthContext);

    return (
        <div className="modal" onClick={closeModal}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <div className="close" onClick={closeModal}>
                    &times;
                </div>

                <div className='header-profile-title mt-4'>功能</div>
                <ul className='modal-menu'>
                    {
                        isAuthenticatedProvider &&
                        (
                            <a className="remove-link-css" href='/as'>
                                <li>
                                    {
                                        false
                                            ?
                                            <img src="https://via.placeholder.com/40" className="post-profile-image" />
                                            :
                                            <div className='header-setting-profile-name'>
                                                <span>J</span>
                                            </div>
                                    }
                                    <span>Jim Liu</span>
                                </li>
                            </a>
                        )
                    }

                    {
                    isAuthenticatedProvider &&
                        (
                            <a className="remove-link-css" href='/aip'>
                                <li>
                                    <i className="icon-lightbulb fa-regular fa-lightbulb"></i>
                                    <span>已發文章</span>
                                </li>
                            </a>
                        )
                    }

                    <a className="remove-link-css" href='/p'>
                        <li>

                            <i className="icon-lightbulb fa-solid fa-magnifying-glass"></i>
                            <span>查看文章</span>

                        </li>
                    </a>

                    <a className="remove-link-css" href='/cp'>
                        <li>
                            <i className="icon-lightbulb fa-regular fa-lightbulb"></i>
                            <span>發起點子</span>
                        </li>
                    </a>

                    {
                        !isAuthenticatedProvider ?
                        (
                            <a className="remove-link-css" href='/l'>
                                <li>
                                    <i className="icon-lightbulb fa-regular fa-user"></i>
                                    <span>登入</span>
                                </li>
                            </a>
                        )
                        :
                        (
                            <div onClick={logout}>
                                <li>
                                    <i className="icon-lightbulb fa-regular fa-user"></i>
                                    <span>登出</span>
                                </li>
                            </div>
                        )
                    }
                </ul>
            </div>
        </div>
    );
};

export default HeaderSettingModal;
