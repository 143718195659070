import React, { createContext, useState, useEffect } from "react";
import { isAuthenticated,setAuthenticated,removeToken } from "../utils/Auth";

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isAuthenticatedProvider, setIsAuthenticated] = useState(false); // 控制認證狀態

  // 檢查 JWT 是否存在於 localStorage
  useEffect(() => {
    setIsAuthenticated(isAuthenticated()); // 如果 token 存在，設為 true
  }, []);

  // 登入函數
  const login = (token) => {
    setAuthenticated(token);
    setIsAuthenticated(true);
  };

  // 登出函數
  const logout = () => {
    removeToken();
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticatedProvider, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
