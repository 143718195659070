import React from "react";

const AccountEditPage1 = ({ formData, updateFormData, nextPage }) => {
    let placeholderproduce = 'test\ntest';
    let placeholdershortintroduce = 'Example 1: LaunchCool CEO, 2024 ~ now, 我要讓你們的點子在這裡都會實現。\nExample 2: 輔仁大學 Fu Jen Catholic University, 學士學位, 電機工程學系, 2015 ~ 2019';
    let placeholderintroduce = 'Example :\n大家好我是Jim是軟體架構師，MBTI是INTJ建築師，所以一直對建造出大家能用的服務充滿熱情，我認為未來個人公司、創業會是趨勢，因此創了一個創業平台LaunchCool，能在上面分享創業點子、找到志同道合的夥伴。此平台是為了所有有夢想的人所創，只要大家還有創業夢LaunchCool Forever。';

    const handleChange = (e) => {
        const { name, value } = e.target;
        updateFormData({ [name]: value });
    };

    return (
        <div className='content-main mb-5'>
            <div className="mb-3 text-end">
                <a href="/as" className="btn">取消</a>
            </div>

            <div className="mb-5">
                <div className='post-create-item-subtitle'>名字</div>
                <label className="post-create-item-title-describe">發揮創意，讓名字成為你獨一無二的標籤。（內容最多20字）</label>
                <input
                    className='input-custom'
                    name='Name'
                    type="text"
                    placeholder='Example : Jim Liu - 樂觀往好處想的技術開拓者'
                    value={formData.Name || ''}
                    onChange={handleChange} />
            </div>

            <div className="mb-5">
                <div className='post-create-item-subtitle'>一句話形容自己</div>
                <label className="post-create-item-title-describe">如果只有20秒，如何讓別人記住你？/ 也可以寫任職工師職位、畢業學校及在學期間。（內容最多20字）</label>
                <textarea name='ShortIntroduce' className="textarea-custom" rows="3"
                    placeholder={placeholdershortintroduce} value={formData.ShortIntroduce || ''} onChange={handleChange}></textarea>
            </div>

            <div className="mb-5">
                <div className='post-create-item-subtitle'>自我介紹</div>
                <label className="post-create-item-title-describe">如何讓別人充分認識你？（內容需填寫 100 字以上）</label>
                <textarea name='IntroduceSelf' className="textarea-custom" rows="9"
                    placeholder={placeholderintroduce} value={formData.IntroduceSelf || ''} onChange={handleChange}></textarea>
            </div>

            <div className="mb-5">
                <div className='post-create-item-subtitle'>聯絡資訊/分享資訊</div>
                <label className="post-create-item-title-describe">IG</label>
                <input
                    className='input-custom mb-1'
                    name='SocialMedia_IG'
                    type="text"
                    value={formData.SocialMedia_IG || ''}
                    onChange={handleChange} />
                <label className="post-create-item-title-describe">Github</label>
                <input
                    className='input-custom mb-1'
                    name='SocialMedia_GitHub'
                    type="text"
                    value={formData.SocialMedia_GitHub || ''}
                    onChange={handleChange} />

                <label className="post-create-item-title-describe">LINE</label>
                <input
                    className='input-custom mb-1'
                    name='SocialMedia_Line'
                    type="text"
                    value={formData.SocialMedia_Line || ''}
                    onChange={handleChange} />
                <label className="post-create-item-title-describe">EMAIL</label>
                <input
                    className='input-custom'
                    name='SocialMedia_EMAIL'
                    type="text"
                    value={formData.SocialMedia_EMAIL || ''}
                    onChange={handleChange} />
            </div>

            <div className="text-end mb-3">
                <div className='btn' onClick={nextPage}>下一步 &gt;</div>
            </div>
        </div>);
}

export default AccountEditPage1;