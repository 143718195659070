import React from "react";

const AccountEditPage2 = ({ formData, updateFormData, nextPage }) => {
    let placeholderskill = 'Example :\n擅長 : 自我成長、探索自我。\n優點 : 善於溝通、陽光正向，跟我合作的人都會覺得我很溫暖很舒服。';
    let placeholderjobexperence = 'Example :\n2019年-2020年 公司名稱 : ＸＸＸＸ金融科技公司\n職位 : 資深前端工程師\n工作內容 :ＸＸＸＸ\n\n2020年-2024年 公司名稱 : ＸＸＸＸ金融科技公司\n職位 : 資深前端工程師\n工作內容 :ＸＸＸＸ';
    let placeholdereducation = 'Example :\n 2019年-2020年 學校名稱 : ＸＸＸＸ大學\n科系 : 資訊工程學系\n學位 : 學士\n\n2020年-2024年 學校名稱 : ＸＸＸＸ大學\n科系 : 資訊工程學系\n學位 : 碩士';

    const handleChange = (e) => {
        const { name, value } = e.target;
        updateFormData({ [name]: value });
    };

    return (
        <div className='content-main mb-5'>

            <div className="d-flex justify-content-between mb-3">
                <div className="btn">
                    <div onClick={nextPage}>&lt; 上一步</div>
                </div>
                <a href="/as" className="btn">取消</a>
            </div>

            <div className="mb-5">
                <div className='post-create-item-subtitle'>專業技能 / 強項 / 優勢</div>
                <label className="post-create-item-title-describe">也許你覺得你毫優點...不！千萬別這樣想！野心、正向也可以是你的優點。（內容需填寫 100 字以上）</label>
                <textarea name='Skill' className="textarea-custom" rows="9"
                    placeholder={placeholderskill} value={formData.Skill || ''} onChange={handleChange}></textarea>
            </div>

            <div className="mb-5">
                <div className='post-create-item-subtitle'>工作經歷</div>
                <label className="post-create-item-title-describe">工作使人蛻變而堅強。告訴我們你的經歷。（內容需填寫 100 字以上）</label>
                <textarea name='EducationalBackground' className="textarea-custom" rows="9"
                    placeholder={placeholderjobexperence} value={formData.JobExperiences || ''} onChange={handleChange}></textarea>
            </div>

            <div className="mb-5">
                <div className='post-create-item-subtitle'>學歷</div>
                <label className="post-create-item-title-describe">一句話介紹 / 3個主要功能介紹 / 詳細說明你的點子（內容需填寫 100 字以上）</label>
                <textarea name='JobExperiences' className="textarea-custom" rows="9"
                    placeholder={placeholdereducation} value={formData.EducationalBackground || ''} onChange={handleChange}></textarea>
            </div>

            <div className="d-flex justify-content-center">
                <div className="btn btnSendCreatePost">確認送出</div>
            </div>
        </div>);
}

export default AccountEditPage2;