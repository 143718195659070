import React, { useEffect, useState } from 'react';
import { getTest } from '../api/api';
import { useNavigate,useLocation } from 'react-router-dom';
import PostIdea from '../components/Post/PostIdea';
import PostIdeaBrief from '../components/Post/PostIdeaBrief';
import AccountBriefSideList from '../components/Account/AccountBriefSideList';
import '../assets/post.css';
import '../assets/common.css';

const Post = () => {
    const [data, setData] = useState([]);
    const [isClassActive, setIsExpanded] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();

    // 取得查詢字串
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');

    // enter this page and mount will trigger this function.
    // dependency is empty array, so it will only trigger once.
    useEffect(() => {
        getTest()
            .then(data => setData(data))
            .catch(error => console.log(error));
    }, []);

    const handleProfileClick = () => {
        // 可以在這裡執行其他操作，例如記錄點擊事件
        navigate(`/a?id=1`);
    };

    // 元件要大寫
    let Component;
    if (id !== '' && id !== null) {
        Component = PostIdea;
    } else {
        Component = PostIdeaBrief;
    }
    
    return (
        <div className="container content-wrapper">
            <div className="row">
                <div className="col-lg-8">
                    <Component />
                    <Component />
                    <Component />
                </div>

                <div className='col-lg-4'>
                    <AccountBriefSideList />
                </div>
            </div>
        </div>
    );
};

export default Post;