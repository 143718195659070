import React, { useEffect, useState, forwardRef,useContext } from 'react';
import '../../assets/header.css';
import { AuthContext } from "../../store/AuthProvider";

const HeaderProfile = forwardRef(({ onClose }, ref) => {
    const { isAuthenticatedProvider, logout } = useContext(AuthContext);

    return (
        <div ref={ref} className='profile-container'>
            <div className="profile-modal">
                <div className="close" onClick={onClose}>
                    &times;
                </div>
                <div className='header-profile-title'>功能</div>
                <ul className="modal-menu">
                    <a className='remove-link-css' href='/as'>
                        <li>
                            {
                                false
                                    ?
                                    <img src="https://via.placeholder.com/40" className="post-profile-image" />
                                    :
                                    <div className='header-setting-profile-name'>
                                        <span>J</span>
                                    </div>
                            }
                            <span>Jim Liu</span>
                        </li>
                    </a>
                    <a className='remove-link-css' href='/aip'>
                        <li>已發文章</li>
                    </a>

                    {
                        isAuthenticatedProvider &&
                        (
                            <div onClick={logout}>
                                <li>
                                    <i className="icon-lightbulb fa-regular fa-user"></i>
                                    <span>登出</span>
                                </li>
                            </div>
                        )
                    }
                </ul>
            </div>
        </div>
    );
});

export default HeaderProfile;